import { component } from 'picoapp'
import gsap from 'gsap'
import { qsa, rect, round } from 'martha'

export default component((node, ctx) => {
  let els = qsa('[data-sticky]', node)
  let nodeRect = null

  ctx.on('resize:reset', ({ screens }) => {
    nodeRect = rect(node)
    els.map((el) => {
      gsap.set(el, { y: 0 })
      el.rect = rect(el)
      el.minWidth = screens?.[el.dataset.screen]
    })
  })

  ctx.on('tick', ({ scroll, ww }) => {
    let liveNodeHeight = node.getBoundingClientRect().height
    els.map((el) => {
      let offset = parseInt(el.dataset.sticky)
      offset = isNaN(offset) || !offset ? 0 : offset
      if (typeof el.minWidth === 'undefined' || ww >= el.minWidth) {
        let top = el.rect.top - scroll - offset
        let bottom = nodeRect.top + liveNodeHeight - scroll
        if (top <= 0) {
          if (bottom > el.rect.height + offset) {
            gsap.set(el, { y: scroll - el.rect.top + offset })
          } else {
            gsap.set(el, { y: liveNodeHeight - el.rect.height })
          }
        } else {
          gsap.set(el, { y: null })
        }
      } else {
        gsap.set(el, { clearProps: 'y' })
      }
    })
  })

  return () => {}
})
