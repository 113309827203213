import { component } from 'picoapp'
import choozy from 'choozy'
import { add, remove, on, rect, round } from 'martha'
import gsap from 'gsap'
import bezier from 'bezier-easing'

export default component((node, ctx) => {
  let { burger, topBun, bottomBun, patty, outer, inner, items } = choozy(
    node,
    'js:header-',
  )

  let tl = gsap.timeline({ paused: true, defaults: { duration: 0.7 } })

  let offClick = on(burger, 'click', () => {
    ctx.emit('header:toggle', { isOpen: !ctx.getState().isOpen })
  })

  ctx.on('header:toggle', ({ isOpen }) => (isOpen ? open() : close()))

  function open() {
    let { dom, ww, wh } = ctx.getState()
    let cache = getCache({ ww, wh })

    tl.clear()
      .add(() => add(dom.body, 'overflow-hidden'))
      .to(
        patty,
        {
          scaleX: 0,
          alpha: 0,
          ease: 'expo',
        },
        0,
      )
      .to(
        topBun,
        {
          scaleX: 1,
          y: '2.6rem',
          rotation: 45,
          ease: 'expo',
        },
        0,
      )
      .to(
        bottomBun,
        {
          scaleX: 1,
          y: '-2.6rem',
          rotation: -45,
          ease: 'expo',
        },
        0,
      )
      // open overlay
      .to(
        [outer, inner],
        {
          transform: `translateY(0%)`,
          ease: bezier(0.785, 0.135, 0.15, 0.86),
        },
        0,
      )
      .set(
        items,
        {
          x: gsap.utils.wrap(cache.map((entry) => entry.x)),
          y: gsap.utils.wrap(cache.map((entry) => entry.y)),
          rotation: gsap.utils.wrap(cache.map((entry) => entry.rotation)),
        },
        0,
      )
      .to(
        items,
        {
          x: 0,
          y: 0,
          rotation: 0,
          stagger: node.dataset.theme === 'clubhouse' ? 0.05 : 0.02,
          ease: bezier(0.23, 1, 0.32, 1),
        },
        0.2,
      )
      .restart()
  }

  function close() {
    let { dom } = ctx.getState()

    tl.clear()
      .add(() => remove(dom.body, 'overflow-hidden'))
      // x to burger
      .to(
        patty,
        {
          scaleX: 1,
          alpha: 1,
          ease: 'expo',
        },
        0,
      )
      .to(
        topBun,
        {
          scaleX: 0.85,
          y: 0,
          rotation: 0,
          ease: 'expo',
        },
        0,
      )
      .to(
        bottomBun,
        {
          scaleX: 0.7,
          y: 0,
          rotation: 0,
          ease: 'expo',
        },
        0,
      )
      // close overlay
      .to(
        [outer, inner],
        {
          transform: gsap.utils.wrap([`translateY(-100%)`, `translateY(100%)`]),
          ease: bezier(0.165, 0.84, 0.44, 1),
        },
        0,
      )
      .restart()
  }

  function getCache({ ww, wh }) {
    return items.map((item, i) => {
      if (node.dataset.theme == 'middle-child') {
        let bounds = rect(item)
        return {
          x: round(
            bounds.right > ww - bounds.left
              ? bounds.right
              : (ww - bounds.left) * -1,
            100,
          ),
          y: round(
            bounds.bottom > wh - bounds.top
              ? bounds.bottom
              : (wh - bounds.top) * -1,
            100,
          ),
          rotation: round((i % 2 ? 90 : -90) * Math.random(), 100),
        }
      } else {
        return {
          x: 0,
          y: '100%',
          rotation: 0,
        }
      }
    })
  }

  return () => {
    ctx.emit('header:toggle', { isOpen: false })
    offClick()
  }
})
