import { component } from 'picoapp'
import choozy from 'choozy'
import { add, on, remove } from 'martha'
import gsap from 'gsap'
import delegate from '../lib/delegate'
import { createFocusTrap } from 'focus-trap'
import Flip from 'gsap/Flip'

gsap.registerPlugin(Flip)

export default component((node, ctx) => {
  let refs = choozy(node, 'js:modal-')
  let { backdrop, modal, backBtn, closeBtn, contentEls } = refs
  contentEls = Array.isArray(contentEls) ? contentEls : [contentEls]

  let tl = gsap.timeline({
    paused: true,
    defaults: { duration: 0.4, ease: 'quint' },
  })

  let focusTrap = createFocusTrap(node, {
    escapeDeactivates: false,
  })

  let prevSlug = window.location.hash.slice(1)

  function handleModalUpdate() {
    let currentSlug = window.location.hash.slice(1)

    if (isOpen()) {
      if (currentSlug !== prevSlug) {
        gsap.to(backBtn, {
          autoAlpha: currentSlug === 'landing' ? 0 : 1,
          duration: 0.4,
          ease: 'quint',
        })

        const flipState = Flip.getState(modal)

        handleSwitch(currentSlug, 0.4, 0.2)

        Flip.from(flipState, {
          duration: 0.4,
          ease: 'quint',
        })
      }
    } else {
      handleSwitch(currentSlug, 0, 0)
      toggle()
    }

    prevSlug = currentSlug
  }

  let offClick = delegate(document, 'a[data-modal]', 'click', handleModalUpdate)

  let offClose = on([closeBtn, backdrop], 'click', close)

  let offKeyup = on(document, 'keyup', ({ key }) => {
    if (key === 'Escape') close()
  })

  ctx.on('modal:toggle', ({ isModalOpen }) =>
    isModalOpen ? handleOpen() : handleClose(),
  )

  function handleSwitch(newSlug, duration, delay) {
    contentEls.forEach((el) => {
      if (el.dataset.modal === newSlug) {
        remove(el, 'absolute')
        gsap.to(el, { autoAlpha: 1, duration, delay, ease: 'quint' })

        if (el.dataset.theme === 'clubhouse') {
          add(modal, '!bg-cream !text-dark-green')
        } else {
          remove(modal, '!bg-cream !text-dark-green')
        }

        if (el.dataset.theme === 'middle-child') {
          add(modal, '!text-green')
        } else {
          remove(modal, '!text-green')
        }
      } else {
        add(el, 'absolute')
        gsap.to(el, { autoAlpha: 0, duration, ease: 'quint' })
      }
    })
  }

  function toggle() {
    ctx.emit('modal:toggle', { isModalOpen: !isOpen() })
  }

  function close() {
    if (isOpen()) ctx.emit('modal:toggle', { isModalOpen: false })
  }

  function isOpen() {
    return ctx.getState().isModalOpen
  }

  function handleOpen() {
    tl.clear()
    tl.set(backdrop, { opacity: 0 }, 'setup')
    tl.set(modal, { y: 25, opacity: 0 }, 'setup')
    tl.set(node, { autoAlpha: 1 }, 'setup')
    tl.add(() => focusTrap.activate(), 'animate')
    tl.to(backdrop, { autoAlpha: 1 }, 'animate')
    tl.to(modal, { y: 0, autoAlpha: 1 }, 'animate')
    tl.restart()
  }

  function handleClose() {
    focusTrap.deactivate()

    tl.clear()
    tl.to(modal, { y: 25, opacity: 0 }, 'animate')
    tl.to(backdrop, { opacity: 0 }, 'animate')
    tl.set(node, { autoAlpha: 0 }, 'after')
    tl.add(() => add(backBtn, 'invisible'), 'after')
    tl.restart()
  }

  return () => {
    ctx.emit('modal:toggle', { isModalOpen: false })
    offClick()
    offClose()
    offKeyup()
  }
})
