import choozy from 'choozy'
import { each } from 'martha'
import { component } from 'picoapp'
import observeRect from '@reach/observe-rect'
import gsap from 'gsap'

export default component((node, ctx) => {
  const { media, images, sections } = choozy(node)

  let mediaRect = null
  let sectionRects = []
  let sectionObservers = []
  let threshold = 0.66

  let mediaObserver = observeRect(media, (rect) => {
    mediaRect = rect
  })

  each(sections, (section, i) => {
    sectionObservers[i] = observeRect(section, (rect) => {
      sectionRects[i] = rect
    })
  })

  mediaObserver.observe()
  each(sectionObservers, (observer) => observer.observe())

  let firstTick = true

  ctx.on('tick', ({ ww }) => {
    if (mediaRect && sectionRects.length) {
      if (ww >= 768) {
        let midMediaTop = mediaRect.top + mediaRect.height * threshold
        let midMediaBottom =
          mediaRect.height -
          mediaRect.bottom +
          mediaRect.height * (1 - threshold)

        each(sections, (section, i) => {
          let rect = sectionRects[i]
          let margin = (mediaRect.height - rect.height) / 2

          gsap.set(section, {
            marginTop: margin,
            marginBottom: margin,
          })

          if (
            rect.top <= midMediaTop &&
            rect.top >
              mediaRect.height - (rect.height + midMediaBottom + margin)
          ) {
            gsap.set(section, { opacity: 1 })
            gsap.set(images[i], { opacity: 1 })
          } else {
            gsap.set(section, { opacity: 0.25 })
            gsap.set(images[i], { opacity: 0 })
          }
        })
      } else {
        gsap.set(sections, { opacity: 1, clearProps: 'marginTop,marginBottom' })
        gsap.set(images, { opacity: 1 })
      }
    }

    if (firstTick) {
      firstTick = false
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          ctx.emit('resize')
        })
      })
    }
  })

  return () => {
    mediaObserver.unobserve()
    each(sectionObservers, (observer) => {
      observer.unobserve()
    })
  }
})
