import Highway from '@dogstudio/highway'
import * as quicklink from 'quicklink'
import gsap from 'gsap'
import app from './app'
import raf from './lib/raf'
import loadFonts from './lib/loadFonts'
import { on, once, size, remove, toggle, qs } from 'martha'
import Default from './transitions/Default'
import LandingToMC from './transitions/LandingToMC'
import LandingToCH from './transitions/LandingToCH'
import Fade from './transitions/Fade'
import Menu from './transitions/Menu'
import invertTheme from './lib/invertTheme'

class Base extends Highway.Renderer {
  onLoad() {
    const { dom, isDayMode, fonts } = app.getState()

    dom.body.setAttribute('data-mode', isDayMode ? 'day' : 'night')

    quicklink.listen()

    on(window, 'resize', this.resize)
    on(document, 'mousemove', this.mousemove)
    raf(app)

    gsap.set('[data-router-view]', { autoAlpha: 1 })

    loadFonts(fonts)
      .then(this.onLoadCompleted)
      .catch((err) => console.log(err))
  }

  onLoadCompleted = () => {
    this.onEnter()

    let { dom } = app.getState()

    once(dom.body, 'transitionend', () => {
      dom.body.removeAttribute('class')
      this.onEnterCompleted()
    })

    remove(dom.body, 'opacity-[0.001]')
  }

  onEnter() {
    if (app.getState().isDayMode) {
      invertTheme()
    }

    this.mount()
  }

  onEnterCompleted() {
    app.emit('enter:completed')
  }

  onLeave() {
    this.unmount()
  }

  onLeaveCompleted() {}

  resize = () => {
    app.emit('resize', size())
  }

  mousemove = ({ clientX: mx, clientY: my }) => {
    app.emit('mousemove', { mx, my })
  }

  tick = () => {
    app.emit('tick')
  }

  mount = () => {
    app.mount()
    this.resize()
  }

  unmount = () => {
    app.unmount()
  }

  setup() {
    this.onLoad()
  }
}

const H = new Highway.Core({
  renderers: {
    default: Base,
  },
  transitions: {
    default: Default,
    contextual: {
      toMC: app.getState().ww >= 768 ? LandingToMC : Fade,
      toCH: app.getState().ww >= 768 ? LandingToCH : Fade,
      menu: Menu,
    },
  },
})

H.on('NAVIGATE_END', ({ location }) => {
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.origin === 'https://middlechildphilly.com' &&
    typeof ga === 'function'
  ) {
    let path = location.pathname
    let page =
      path.split('').pop() === '/' && path.length > 1 ? path.slice(0, -1) : path
    ga('send', 'pageview', page)
  }
})

on(window, 'keyup', ({ key, ctrlKey }) => {
  if (key?.toLowerCase?.() === 'g' && ctrlKey) {
    toggle(qs('#g'), 'opacity-0')
  }
})
