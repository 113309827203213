import choozy from 'choozy'
import gsap from 'gsap'
import { on } from 'martha'
import { component } from 'picoapp'
import { createFocusTrap } from 'focus-trap'

export default component((node, ctx) => {
  const {
    form,
    submitBtn,
    confirmation,
    backdrop,
    confirmationContent,
    closeBtn,
    // toggleBtn,
  } = choozy(node)

  confirmation.remove()
  document.body.appendChild(confirmation)

  let isConfirmationOpen = false

  let offSubmit = on(form, 'submit', async (e) => {
    e.preventDefault()

    const requiredFields = node.querySelectorAll('[data-required]')

    for (const field of requiredFields) {
      const inputs = field.querySelectorAll('input')
      const isAnySelected = Array.from(inputs).some((input) => input.checked)

      if (!isAnySelected) {
        inputs.forEach((input) => {
          input.setCustomValidity('Please select at least one option.')
          input.reportValidity()
        })
        return
      } else {
        inputs.forEach((input) => input.setCustomValidity(''))
      }
    }

    submitBtn.disabled = true
    submitBtn.textContent = 'Submitting…'

    try {
      const formData = new FormData(form)

      const json = {}
      formData.forEach((value, key) => {
        if (json[key]) {
          json[key] = `${json[key]}, ${value}`
        } else {
          json[key] = value
        }
      })

      await fetch(`https://submit-form.com/${node.dataset.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(json),
      })

      submitBtn.disabled = false
      submitBtn.textContent = 'Submit'
      form.reset()
      showConfirmation()
    } catch (error) {
      console.error(error)
      submitBtn.disabled = false
      submitBtn.textContent = 'Submit'
    }
  })

  let offClick = on(closeBtn, 'click', () => {
    hideConfirmation()
  })

  // on(toggleBtn, 'click', () => {
  //   showConfirmation()
  // })

  const tl = gsap.timeline({
    paused: true,
    defaults: { duration: 0.4, ease: 'quint' },
  })

  const focusTrap = createFocusTrap(confirmation, {
    escapeDeactivates: false,
  })

  let offKeyup = on(document, 'keyup', ({ key }) => {
    if (key === 'Escape' && isConfirmationOpen) hideConfirmation()
  })

  function showConfirmation() {
    isConfirmationOpen = true

    tl.clear()
      .set(backdrop, { autoAlpha: 0 })
      .set(confirmationContent, { alpha: 0, yPercent: 100, rotation: 20 })
      .set(confirmation, { autoAlpha: 1 })
      .add(() => focusTrap.activate())
      .to(backdrop, { autoAlpha: 1 }, 0)
      .to(
        confirmationContent,
        { alpha: 1, yPercent: 0, rotation: 0, duration: 1 },
        0,
      )
      .restart()
  }

  function hideConfirmation() {
    isConfirmationOpen = false

    focusTrap.deactivate()

    tl.clear()
      .to(confirmationContent, { yPercent: -100, rotation: -20 }, 0)
      .to(confirmation, { autoAlpha: 0 }, 0)
      .restart()
  }

  return () => {
    offSubmit()
    offClick()
    offKeyup()
    confirmation.remove()
  }
})
