import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { rect, qs, on, round, lerp } from 'martha'
import delegate from './delegate'

gsap.registerPlugin(ScrollToPlugin)

export default function raf(app) {
  let target = 0
  let current = 0
  let ease = 0.15

  gsap.ticker.fps(-1)
  gsap.ticker.add(tick)

  on(window, 'scroll', scroll)
  app.on('scroll:to', scrollTo)
  app.on('scroll:reset', reset)
  app.on('resize:reset', resize)

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (window.location.hash) {
            const target = qs(window.location.hash)
            if (target) {
              app.emit('scroll:to', null, target, 0)
            }
          }
        })
      })
    })
  })

  delegate(document, 'a[href^="#"]', 'click', (el, ev) => {
    ev.preventDefault()
    const href = el.href.split('#').pop()
    const target = qs(`#${href}`)

    if (target) {
      app.emit('scroll:to', null, target)
    }
  })

  function tick() {
    current =
      app.getState().ww >= 768
        ? round(lerp(current, target, ease), 100)
        : target
    app.emit('tick', { scroll: current })
  }

  function scroll() {
    target = window.scrollY
  }

  function scrollTo(_, target, duration = 0.5) {
    const offset = window.scrollY + rect(target).top
    const padding = rect(qs('[data-scroll-padding-top]'))?.bottom ?? 0
    const y = offset - padding
    const temp = { y: current }

    gsap.to(temp, {
      y: y < 0 ? 0 : y,
      duration,
      ease: 'expo.inOut',
      onStart() {
        app.hydrate({ isAutoScrolling: true })
      },
      onUpdate() {
        window.scroll(0, temp.y)
        target = current = temp.y
      },
      onComplete() {
        app.hydrate({ isAutoScrolling: false })
      },
    })
  }

  function reset() {
    target = current = 0
  }

  function resize() {
    current = target
  }
}
